<template>
  <div>
    <div class="txt-head mb-5">Payment</div>
    <div class="text-center txt-head">My Bag</div>
    <div class="mb-5">
      <CartTable
        :items="cartItems"
        :deletable="false"
        :lock="true"
        @increaseItem="increaseItem"
        @decreaseItem="decreaseItem"
        @removeItem="removeItem"
      ></CartTable>
    </div>

    <v-row>
      <v-col>
        <v-flex shrink> <b>Shipping Detail</b> </v-flex>
        <v-row>
          <v-col>
            <div class="layout-border mt-2" v-if="addressDelivery">
              <div class="txt-big">Delivery address</div>
              <div class="mb-2 mt-1 txt-detail px-2">
                {{ addressDelivery.fullName }} ({{
                  addressDelivery.companyName
                }})
              </div>
              <div class="txt-detail px-2">
                {{ addressDelivery.addressName }}
                {{ addressDelivery.cityName }}
                {{ addressDelivery.zipCode }}
                {{ addressDelivery.countryName }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="layout-border" v-if="addressBilling">
              <div class="txt-big">Billing address</div>
              <div class="mb-2 mt-1 txt-detail px-2">
                {{ addressBilling.fullName }} ({{ addressBilling.companyName }})
              </div>
              <div class="txt-detail px-2">
                {{ addressBilling.addressName }}
                {{ addressBilling.cityName }}
                {{ addressBilling.zipCode }}
                {{ addressBilling.countryName }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12 col-md-6 col-lg-4">
        <v-layout justify-space-between align-center mb-2>
          <v-flex shrink>
            {{ cartItems.length }}
            {{ cartItems.length > 1 ? 'items' : 'item' }}
          </v-flex>
          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
            SGD {{ subTotal }}
          </v-flex>
        </v-layout>
        <v-layout justify-space-between align-center mb-2>
          <v-flex shrink>Total Product Cost :</v-flex>
          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
            SGD {{ subTotal }}
          </v-flex>
        </v-layout>
        <v-layout justify-space-between align-center mb-2>
          <v-flex shrink>Shipping fee :</v-flex>
          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">
            {{
              deliveryCost === 0
                ? 'FREE'
                : 'SGD ' + formatNumber(deliveryCost, 2)
            }}
          </v-flex>
        </v-layout>
<!--        <v-layout justify-space-between align-center mb-5>-->
<!--          <v-flex shrink>Tax :</v-flex>-->
<!--          <v-flex shrink class="txt-bigger font-weight-light darkblue-color">-->
<!--            SGD {{ formatNumber(tax, 2) }}-->
<!--          </v-flex>-->
<!--        </v-layout>-->
        <Divider long="100%"></Divider>
        <v-layout justify-space-between align-center mb-5>
          <v-flex shrink class="font-weight-bold">Sub Total :</v-flex>
          <v-flex shrink class="font-weight-bold txt-head darkblue-color">
            SGD {{ totalIncludedTax }}
          </v-flex>
        </v-layout>
        <Button
          class="mb-5"
          type="dark"
          label="PAYMENT"
          :block="true"
          @onClick="eventPayment"
        ></Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CartTable from '../../components/CartTable'
import Button from '../../components/input/Button'
import Divider from '../../components/Divider'
import { formatNumber } from "@/plugins/global-funcs"

export default {
  name: 'Payment',
  components: {
    CartTable,
    Button,
    Divider
  },
  props: {
    addressDelivery: {
      default: []
    },
    addressBilling: {
      default: []
    },
    cartItems: {
      default: []
    },
    deliveryCost: {
      default: 0
    },
    tax: {
      default: 0
    }
  },
  data () {
    return {
      formatNumber: formatNumber
    }
  },
  computed: {
    subTotal: function () {
      let sum = 0.0
      for (const x of this.cartItems) {
        sum += parseFloat(x.price_per_piece * x.quantity, 2)
      }
      return parseFloat(sum) || parseFloat(sum) == 0
        ? formatNumber(sum, 2)
        : '-'
    },
    totalIncludedTax: function () {
      let total =
        parseFloat(this.subTotal) +
        parseFloat(this.deliveryCost)
        // + parseFloat(this.tax)
      return parseFloat(total) || parseFloat(total) == 0
        ? formatNumber(total, 2)
        : '-'
    }
  },
  methods: {
    increaseItem(item) {
      this.$emit('increaseItem', item)
    },
    decreaseItem(item) {
      this.$emit('decreaseItem', item)
    },
    removeItem(item) {
      this.$emit('removeItem', item)
    },
    eventPayment () {
      this.$emit('onPayment')
    }
  }
}
</script>

<style scoped></style>
