<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <v-stepper v-model="step" style="box-shadow: none">
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step
            color="var(--bkk-darkblue)"
            :class="{ 'step-active': step == 1 }"
            :complete="step > 1"
            step="1"
          >
            Login
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            color="var(--bkk-darkblue)"
            :class="{ 'step-active': step == 2 }"
            class="pointer"
            :complete="step > 2"
            step="2"
            @click="backToAddress"
          >
            Address
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            color="var(--bkk-darkblue)"
            :class="{ 'step-active': step == 3 }"
            step="3"
          >
            Payment
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <Signin
              :only-facebook-login="true"
              :signup-email="signupEmail"
              @onChangeSignupEmail="signupEmail = $event"
              @onSignupSuccess="step = 2"
              :signup-detail="signupDetail"
              @setPassword="signupDetail.password = $event"
              @setFirstname="signupDetail.firstname = $event"
              @setLastname="signupDetail.lastname = $event"
              @onLogin="step = 2"
            ></Signin>
          </v-stepper-content>

          <v-stepper-content step="2">
            <Address @onNext="checkoutTopayment"></Address>
          </v-stepper-content>

          <v-stepper-content step="3">
            <Payment
              :cart-items="inCart"
              :delivery-cost="shippingCost"
              :addressDelivery="addressDelivery"
              :addressBilling="addressBilling"
              @increaseItem="increaseItem"
              @decreaseItem="decreaseItem"
              @removeItem="removeItem"
              @onPayment="onPayment"
            ></Payment>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import Signin from './StepPages/Signin'
import Address from './StepPages/Address'
import Payment from './StepPages/Payment'
import { mapState } from 'vuex'
import ServiceAPI from '../services'
import Loading from '../components/Loading'
// import Divider from "../components/Divider";
// import Shipping from "./StepPages/Shipping";

export default {
  name: 'CheckoutStep',
  components: {
    Signin,
    Address,
    Payment,
    Loading
    // Divider
  },
  data() {
    return {
      step: 1,
      isLoading: false,
      addressDelivery: [],
      addressBilling: [],
      signupEmail: '',
      signupDetail: {
        password: '',
        firstname: '',
        lastname: ''
      },
      deliveryCost: 5
    }
  },
  created() {
    if (this.accessToken) {
      this.step = 2
      this.getDetailAddress(this.useAddressID)
    }
    // if (this.accessToken) {
    //   if (this.statusCart.stepState) {
    //     this.step = this.statusCart.stepState
    //     this.getDetailAddress(this.useAddressID)
    //   } else {
    //     this.step = 2
    //   }
    // }
  },
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      cartDetail: (state) => state.Cart.cartDetail,
      shippingCost: (state) => state.Address.shippingCost,
      accessToken: (state) => state.Auth.userDetail.access_token,
      statusCart: (state) => state.Cart.statusCart,
      useAddressID: (state) => state.Cart.statusCart.useAddressID
    })
  },
  methods: {
    increaseItem(item) {
      this.$store.dispatch('Cart/increaseOne', item)
    },
    decreaseItem(item) {
      this.$store.dispatch('Cart/decreaseOne', item)
    },
    removeItem(item) {
      this.$store.dispatch('Cart/removeItem', item)
    },
    checkoutTopayment(data) {
      // ::TODO save State (ShipAddressID and CommentAddress) method = saveShipAddress
      this.$store.dispatch('Cart/saveShipAddress', data)
      this.getDetailAddress(data.useAddressID)
      this.step = 3
    },
    async getDetailAddress(addressID) {
      this.isLoading = true
      let res = await ServiceAPI.address.getDetailbyID({
        addressID: addressID,
        access_token: this.accessToken
      })
      this.isLoading = false
      if (res.status == 'success' && res.data.length !== 0) {
        this.addressDelivery = res.data.delivery
        this.addressBilling = res.data.billing
      }
    },
    async onPayment () {
      let data = {
        access_token: this.accessToken,
        cart_id: this.cartDetail.id,
        address_id: this.addressDelivery.id,
        description: this.statusCart.txtComment
      }
      this.isLoading = true
      let res = await this.$store.dispatch('Payment/onHandlePayment', data)
      if (res.status === 200) {
        window.open(res.data.url)
      }
      this.$store.commit('Cart/clearShipAddress')
      this.isLoading = false
    },
    backToAddress () {
      if (this.accessToken) {
        this.step = 2
      }
    }
  }
}
</script>

<style scoped>
.step-active {
  border-bottom: solid 2px var(--bkk-darkblue);
}
.layout-border {
  border: solid 2px #c4c4c4;
}
.layout-border-active {
  border: solid 2px var(--bkk-darkblue) !important;
}
</style>
