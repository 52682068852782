<template>
  <div>
    <Loading :loading="isLoading"></Loading>
    <div class="txt-head mb-5">Address</div>
    <!--  form address  -->
    <div class="layout-border py-3 px-5" v-if="firstStep">
      <div class="txt-m-head mb-5">Your Address</div>
      <div class="mb-5">
        To add a new address, please fill out the form below.
      </div>
      <div class="txt-bigger mb-5">- Delivery address</div>
      <div class="mb-5">
        <v-row>
          <v-col lg="6" md="12">
            <TextField
              placeholder="First name"
              @onChange="form.delivery.firstName = $event"
              :value="form.delivery.firstName"
            ></TextField>
          </v-col>
          <v-col lg="6" md="12">
            <TextField
              placeholder="Last name"
              @onChange="form.delivery.lastName = $event"
              :value="form.delivery.lastName"
            ></TextField>
          </v-col>
        </v-row>
      </div>
      <div class="mb-5">
        <TextField
          placeholder="Address"
          @onChange="form.delivery.addressName = $event"
          :value="form.delivery.addressName"
        ></TextField>
      </div>
      <div class="mb-5">
        <TextField
          placeholder="Home Phone"
          inputRule="value=value.replace(/[^\d]/g,'')"
          @onChange="form.delivery.homePhone = $event"
          :value="form.delivery.homePhone"
        ></TextField>
      </div>
      <div class="mb-5">
        <TextField
          placeholder="Mobile Phone"
          inputRule="value=value.replace(/[^\d]/g,'')"
          @onChange="form.delivery.mobilePhone = $event"
          :value="form.delivery.mobilePhone"
        ></TextField>
      </div>
      <div class="mb-5">
        <v-row>
          <v-col lg="4" md="12">
            <TextField
                placeholder="City/ State"
                @onChange="form.delivery.cityName = $event"
                :value="form.delivery.cityName"
            ></TextField>
          </v-col>
          <v-col lg="4" md="12">
            <TextField
                placeholder="Zip / Post code"
                inputRule="value=value.replace(/[^\d]/g,'')"
                @onChange="form.delivery.zipCode = $event"
                :value="form.delivery.zipCode"
            ></TextField>
          </v-col>
          <v-col lg="4" md="12">
            <SelectBox
                placeholder="Country"
                :items="countries"
                item_text="name"
                item_value="name"
                @onChange="form.delivery.countryName = $event"
                :value="form.delivery.countryName"
            ></SelectBox>
          </v-col>
        </v-row>
      </div>
      <CheckBox
        class="mb-5"
        :is-check="use_billing"
        @onChange="eventChange"
        label="Use the delivery address as the billing address"
      ></CheckBox>
      <div v-if="!use_billing">
        <div class="txt-bigger mb-5">- Billing address</div>
        <div class="mb-5">
          <v-row>
            <v-col lg="6" md="12">
              <TextField
                placeholder="First name"
                @onChange="form.billing.firstName = $event"
                :value="form.billing.firstName"
              ></TextField>
            </v-col>
            <v-col lg="6" md="12">
              <TextField
                placeholder="Last name"
                @onChange="form.billing.lastName = $event"
                :value="form.billing.lastName"
              ></TextField>
            </v-col>
          </v-row>
        </div>
        <div class="mb-5">
          <TextField
            placeholder="Address"
            @onChange="form.billing.addressName = $event"
            :value="form.billing.addressName"
          ></TextField>
        </div>
        <div class="mb-5">
          <TextField
            placeholder="Home Phone"
            inputRule="value=value.replace(/[^\d]/g,'')"
            @onChange="form.billing.homePhone = $event"
            :value="form.billing.homePhone"
          ></TextField>
        </div>
        <div class="mb-7">
          <TextField
            placeholder="Mobile Phone"
            inputRule="value=value.replace(/[^\d]/g,'')"
            @onChange="form.billing.mobilePhone = $event"
            :value="form.billing.mobilePhone"
          ></TextField>
        </div>
        <div class="mb-5">
          <v-row>
            <v-col lg="4" md="12">
              <TextField
                  placeholder="City / State"
                  @onChange="form.billing.cityName = $event"
                  :value="form.billing.cityName"
              ></TextField>
            </v-col>
            <v-col lg="4" md="12">
              <TextField
                  placeholder="Zip / Post code"
                  inputRule="value=value.replace(/[^\d]/g,'')"
                  @onChange="form.billing.zipCode = $event"
                  :value="form.billing.zipCode"
              ></TextField>
            </v-col>
            <v-col lg="4" md="12">
              <SelectBox
                  placeholder="Country"
                  :items="countries"
                  item_text="name"
                  item_value="name"
                  @onChange="form.billing.countryName = $event"
                  :value="form.billing.countryName"
              ></SelectBox>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="mb-3 d-inline-flex">
        <Button
            class="mr-3"
            type="dark"
            label="Save"
            width="100px"
            :disabled="cantSubmit"
            @onClick="eventSave"
        ></Button>
        <Button
            v-if="Object.keys(listAddress).length >= 1"
            label="Cancel"
            width="100px"
            @onClick="firstStep = false"
        ></Button>
      </div>
    </div>
    <!--  Choose addresses  -->
    <div v-else>
      <div v-if="Object.keys(listAddress).length > 1">
        <div class="txt-bigger mb-5">Choose a delivery address :</div>
        <div class="mb-5">
          <SelectBox
            :items="selectAddress"
            item_text="text"
            item_value="value"
            :value="selectAddress[0]"
            @onChange="changeAddress"
          ></SelectBox>
        </div>
      </div>
      <CheckBox
        class="mb-5"
        :isCheck="use_billing"
        @onChange="ChangeUseDelivery"
        label="Use the delivery address as the billing address"
      ></CheckBox>

      <div class="layout-border pa-5 mb-5" v-if="selectedAddressDelivery">
        <div class="txt-bigger mb-5">Your delivery address</div>
        <div class="mb-2 txt-detail">
          {{ selectedAddressDelivery.fullName }}
        </div>
        <div class="mb-2 txt-detail">
          {{ selectedAddressDelivery.addressName }}
        </div>
        <div class="mb-2 txt-detail">
          {{ selectedAddressDelivery.cityName }}
          {{ selectedAddressDelivery.zipCode }}
          {{ selectedAddressDelivery.countryName }}
        </div>
      </div>

      <div class="layout-border pa-5 mb-5" v-if="selectedAddressBilling && !use_billing">
        <div class="txt-bigger mb-5">Your billing address</div>
        <div class="mb-2 txt-detail">
          {{ selectedAddressBilling.fullName }}
        </div>
        <div class="mb-2 txt-detail">
          {{ selectedAddressBilling.addressName }}
        </div>
        <div class="mb-2 txt-detail">
          {{ selectedAddressBilling.cityName }}
          {{ selectedAddressBilling.zipCode }}
          {{ selectedAddressBilling.countryName }}
        </div>
      </div>
      <Button
        class="mb-5"
        type="dark"
        @onClick="firstStep = true"
        label="Add a new address"
      ></Button>
      <div class="txt-detail mb-5">
        If you would like to add a comment about your order, please write it in
        the field below.
      </div>
      <v-textarea outlined v-model="txtComment"></v-textarea>
      <v-layout justify-space-between>
        <Button
          label="Continue shopping"
          @onClick="$router.push({ name: 'Home' })"
          prepend-icon="chevron_left"
        ></Button>
        <Button type="dark" label="Checkout" @onClick="eventNext"></Button>
      </v-layout>
    </div>
  </div>
</template>

<script>
import TextField from '../../components/input/TextField'
import Button from '../../components/input/Button'
import SelectBox from '../../components/input/SelectBox'
import CheckBox from '../../components/input/CheckBox'
import ServiceAPI from '../../services'
import Loading from '../../components/Loading'
import { mapState } from 'vuex'
import { countries } from "@/assets/data/countries"
import { dataIsCorrect } from "@/plugins/global-funcs"

export default {
  components: {
    Loading,
    Button,
    TextField,
    SelectBox,
    CheckBox
  },
  data() {
    return {
      countries: countries,
      firstStep: true,
      isLoading: true,
      useAddressID: null,
      txtComment: null,
      listAddress: [],
      selectAddress: [],
      use_billing: true,
      selectedAddressDelivery: [],
      selectedAddressBilling: [],
      form: {
        delivery: {
          fullName: null,
          firstName: null,
          lastName: null,
          countryName: null,
          cityName: null,
          zipCode: null,
          addressName: null,
          homePhone: null,
          mobilePhone: null
        },
        billing: {
          fullName: null,
          firstName: null,
          lastName: null,
          countryName: null,
          cityName: null,
          zipCode: null,
          addressName: null,
          homePhone: null,
          mobilePhone: null
        }
      }
    }
  },
  computed: {
    ...mapState({
      accessToken: (state) => state.Auth.userDetail.access_token,
      is_address: (state) => state.Auth.userDetail.is_address
    }),
    cantSubmit: function () {
      if (this.use_billing) {
        return !(
            dataIsCorrect(this.form.delivery.firstName) &&
            dataIsCorrect(this.form.delivery.lastName) &&
            dataIsCorrect(this.form.delivery.addressName) &&
            dataIsCorrect(this.form.delivery.homePhone) &&
            dataIsCorrect(this.form.delivery.mobilePhone) &&
            dataIsCorrect(this.form.delivery.cityName) &&
            dataIsCorrect(this.form.delivery.zipCode) &&
            dataIsCorrect(this.form.delivery.countryName)
        )
      }
      return !(
          dataIsCorrect(this.form.delivery.firstName) &&
          dataIsCorrect(this.form.delivery.lastName) &&
          dataIsCorrect(this.form.delivery.addressName) &&
          dataIsCorrect(this.form.delivery.homePhone) &&
          dataIsCorrect(this.form.delivery.mobilePhone) &&
          dataIsCorrect(this.form.delivery.cityName) &&
          dataIsCorrect(this.form.delivery.zipCode) &&
          dataIsCorrect(this.form.delivery.countryName) &&
          dataIsCorrect(this.form.billing.firstName) &&
          dataIsCorrect(this.form.billing.lastName) &&
          dataIsCorrect(this.form.billing.addressName) &&
          dataIsCorrect(this.form.billing.homePhone) &&
          dataIsCorrect(this.form.billing.mobilePhone) &&
          dataIsCorrect(this.form.billing.cityName) &&
          dataIsCorrect(this.form.billing.zipCode) &&
          dataIsCorrect(this.form.billing.countryName)
      )
    }
  },
  async created() {
    if (this.is_address) {
      await this.fetchAPIAddress()
      this.isLoading = false
    } else {
      this.firstStep = true
      this.isLoading = false
    }
  },
  methods: {
    async changeAddress(addressID) {
      this.getDetailAddress(addressID)
      this.$store.dispatch('Address/useAddressID', addressID)
      this.isLoading = true
    },
    async eventSave() {
      let _this = this
      this.isLoading = true
      this.form.access_token = this.accessToken
      this.form.use_billing = this.use_billing
      this.form.fullName = this.form.firstName + ' ' + this.form.lastName
      let res = await ServiceAPI.address.saveNewAddress(this.form)
      if (res.status == 'success') {
        this.$store.dispatch('Auth/saveAddress', true).then(() => {
          _this.fetchAPIAddress()
          _this.firstStep = false
          _this.isLoading = false
        })
      }
    },
    eventNext() {
      this.$emit('onNext', {
        useAddressID: this.useAddressID,
        step: 3,
        txtComment: this.txtComment
      })
    },
    eventChange() {
      this.use_billing = !this.use_billing
      if (this.use_billing) {
        this.form.billing = this.form.delivery
      } else {
        this.form.billing = {
          fullName: null,
          firstName: null,
          lastName: null,
          countryName: null,
          cityName: null,
          zipCode: null,
          addressName: null,
          homePhone: null,
          mobilePhone: null
        }
      }
    },
    ChangeUseDelivery() {
      this.use_billing = !this.use_billing
      this.isLoading = true
      if (this.use_billing) {
        this.selectedAddressBilling = this.selectedAddressDelivery
        // console.log('this.selectedAddressBilling', this.selectedAddressBilling)
        this.isLoading = false
      } else {
        // console.log('this.useAddressID', this.useAddressID)
        this.getDetailAddress(this.useAddressID)
      }
    },
    saveAddress() {},
    async getDetailAddress(addressID) {
      let res = await ServiceAPI.address.getDetailbyID({
        addressID: addressID,
        access_token: this.accessToken
      })
      this.useAddressID = addressID
      if (res.status == 'success') {
        this.selectedAddressDelivery = res.data.delivery
        this.selectedAddressBilling = res.data.billing
        if (res.data.useBilling) {
          this.use_billing = true
        }
        this.isLoading = false
      }
    },
    async fetchAPIAddress() {
      const res = await ServiceAPI.address.getAddress({
        access_token: this.accessToken
      })
      if (res.status == 'success') {
        console.log('res.data', res.data)
        if (res.data.length == 0) {
          this.firstStep = true
        } else {
          this.firstStep = false
          this.listAddress = res.data
          this.selectAddress = []
          Object.values(this.listAddress).map((d) => {
            if (typeof d != "number") {
              this.selectAddress = [
                ...this.selectAddress,
                {
                  text: d.delivery.fullName,
                  value: d.delivery.id
                }
              ]
            } else {
              this.selectAddress.push(d)
            }
          })
          this.$store.dispatch('Address/onSelectAddress', this.selectAddress)
          this.$store.dispatch(
            'Address/useAddressID',
            this.listAddress[0]['delivery'].id
          )
          this.useAddressID = this.listAddress[0]['delivery'].id
          this.selectedAddressDelivery = this.listAddress[0]['delivery']
          this.selectedAddressBilling = this.listAddress[0]['billing']
          if (this.listAddress[0]['delivery'].useBilling) {
            this.use_billing = true
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.layout-border {
  border: solid 2px #c4c4c4;
}
</style>
